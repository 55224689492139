import React from 'react';
import InputAdornment from '@material-ui/core/InputAdornment'
import TextField from '@material-ui/core/TextField';
import NumberFormat from 'react-number-format'
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';

const NumberFormatCustom = ({input, inputRef, onChange, decimalScale = 2, thousandSeparator = ".", ...rest }) => {

  return (
    <NumberFormat
      {...rest}
      getInputRef={inputRef}
      onValueChange={values => input.onChange(values.value)}
      value={input.value}
      isNumericString
      thousandSeparator={thousandSeparator}
      decimalSeparator=","
      decimalScale={decimalScale}
      fixedDecimalScale
    />
  )
}

const MoneyField = ({shrinkLabel, meta, ...props}) => {
  return (
    <FormControl style={{width: '100%'}}>
      <TextField
        name='moneyField'
        {...props}
        error={meta.error && meta.touched}
        inputProps={{
          input: props.input
        }}
        InputLabelProps={{ shrink: shrinkLabel || !!props.input.value }}
        InputProps={{
          ...props.InputProps,
          input: props.input,
          startAdornment: !!props.input.value ? <InputAdornment position='start'>R$</InputAdornment> : null,
          inputComponent: MoneyFormat
        }}
        placeholder="0,00"
      />
      <FormHelperText error>{meta.touched && meta.error}</FormHelperText>
    </FormControl>
  )
}

const MoneyFormat = ({ input, inputRef, onChange,  ...rest }) => {
  return (
    <NumberFormatCustom
      inputRef={inputRef}
      onChange={onChange}
      input={input}
      {...rest}
      decimalScale={2}
      fixedDecimalScale={true}
    />
  )
}

export default MoneyField;

import {useContext, useEffect, useCallback} from 'react'
import { AuthContext } from '../../Components/Login'
import api from '../../services/api'

const VerifyLogin = ()=> {
  const context = useContext(AuthContext)

  const verifyToken = useCallback(async () => {
    const response = await api.ping()
    if (response.status === 401) {
      context.loggedin && context.logout()
    }
  }, [context])

  useEffect(() => {
    verifyToken()
  }, [verifyToken])
  return null
}

export default VerifyLogin
